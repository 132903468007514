@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.VirtualProductActions {
    &.ProductActions {
        @include mobile {
            margin-block-end: 20px;
        }

        @include tabletAndAbove {
            border: 0;
            box-shadow: none;
            padding: 0 32px 32px;
        }
    }

    &-PriceWrapper {
        margin-block-start: 20px;

        @include mobile {
            margin-block-start: 10px;
        }
    }

    &-LowestPrice {
        margin-block-start: 26px;
    }

    &-AddToCartWrapper {
        box-shadow: none;
        margin-block-start: 10px;

        @include flex($wrap: wrap);

        .ProductActions {
            &-ButtonsWrapper {
                display: block;
                width: 100%;
                margin-block-end: 32px;

                .ProductActions {
                    &-AddToCart {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }

    &-ShortDescription {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
