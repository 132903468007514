@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ImageZoomPopup {
    --zoom-gallery-nav-height: 115px;

    height: 100%;

    &.Popup {
        height: 100%;
    }

    .Popup-Body {
        padding-block-start: 0;
    }

    &-PopupContent.Popup-Content {
        --popup-header-color: var(--color-black);
        --popup-header-background: var(--color-white);

        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;

        .Popup-Header {
            justify-content: flex-end;
        }

        .Popup-Body {
            height: calc(100% - 49px);
        }

        .Popup-CloseBtn {
            inset-inline: auto 10px;
            inset-block-start: 10px;
        }
    }

    .ProductGallery {
        margin-block-start: 0;
        height: 100%;

        &:not(.ProductGallery_isArrangement) {
            // weird css build fix
            flex-direction: column;
            flex-wrap: nowrap;
        }

        &-MainSlider {
            flex-grow: 1;

            .slick-slider {
                display: flex;
                align-items: center;
                height: 100%;
                max-width: 100%;

                @include desktopAndAbove {
                    max-width: calc(100% - 150px);
                }

                img {
                    display: inline-block;
                    max-width: initial;
                    position: initial;
                    width: initial;
                }
            }

            .slick-list {
                width: 100%;
            }

            .slick-slide {
                cursor: pointer;
            }
        }

        &-ImageWrapper {
            height: 100%;
        }

        &-SliderImage {
            text-align: center;
        }


        &-NavSlider {
            margin-block-start: 0;

            .slick-slider {
                max-width: 100%;

                img {
                    max-height: 105px;
                }
            }

            .slick-track {
                justify-content: flex-start;
            }

            .slick-prev {
                inset-inline-start: -12px;
            }

            .slick-next {
                inset-inline-end: -12px;
            }
        }

        &-SliderWrapper {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: calc(100% - var(--zoom-gallery-nav-height) - 60px) 
                var(--zoom-gallery-nav-height);
            height: 100%;
        }

        &-FloatingButton {
            display: none;
        }

        .ImageMagnify {
            max-height: 100%;
            inset-block-start: 50%;
            transform: translateY(-50%);

            &-Image {
                max-height: calc(100vh - var(--zoom-gallery-nav-height) - 126px)
            }
        }

        .Slider-Wrapper {
            .Image {
                align-items: center;
            }
        }
    }

    .ArrangementProductGallery {
        &-SliderWrapper {
            display: initial;
            height: calc(100% - 150px);
            z-index: 4;

            .ImageMagnify {
                inset-block-start: initial;
                transform: initial;
            }
        }
    }

    .Slider {
        padding-block-end: 0;
    }

    .Slider-Arrow {
        display: none;
    }
    
    .Slider-Wrapper {
        .Image {
            @include flex($justify: center)
        }
    }

    .ProductLabel-Text {
        display: none;
    }

    .VideoThumbnail-Thumbnail {
        padding: 0;
    }

    .slick-list {
        height: 100%;
    }
    
    .slick-track {
        height: 100%;
    }
    
    .slick-slide > div {
        height: 100%;
    }
}
