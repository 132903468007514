@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CarouselScrollArrow {
    $arrow-size: 25px;

    z-index: 1;
    transition: opacity 200ms;
    cursor: pointer;
    width: $arrow-size;
    height: $arrow-size;

    .ChevronIcon {
        width: 100%;
        height: 100%;
    }

    &:hover {
        transform: scale(1.2);
    }

    &_isNextArrow {
        transform: none;

        &:hover {
            transform: scale(1.2);
        }
    }

    &_isInvisible {
        visibility: hidden;
    }
}
