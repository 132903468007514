@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-gallery-min-height: 400px;
}

.ProductGallery {
    @include mobile {
        margin: 0 0 16px;
    }

    margin: 32px 0 0;
    min-width: unset;
    height: auto;

    .Slider-Wrapper {
        .Image {
            @include flex($justify: center)
        }
    }

    .slick-slider {
        width: 100%;
        margin: 0 auto;
        max-width: 400px;

        img {
            position: static;
            max-height: 400px;
        }
    }

    &-MainSlider {
        min-height: var(--product-gallery-min-height);

        .slick-slide {
            cursor: zoom-in;
            min-height: var(--product-gallery-min-height);
        }

        /* stylelint-disable-next-line */
        .slick-prev,
        .slick-next {
            border: 1px solid #E8E9E9;
            background-color: $white;
            height: 64px;
            width: 64px;
            padding: 20px;
            z-index: 1;
        }

        .slick-prev {
            inset-inline-start: 0px;

            @include above1200 {
                inset-inline-start: -25px;
            }

            @include above1480 {
                inset-inline-start: -75px;
            }
        }

        .slick-next {
            inset-inline-end: 0px;

            @include above1200 {
                inset-inline-end: -25px;
            }

            @include above1480 {
                inset-inline-end: -75px;
            }
        }

        .slick-track {
            @include flex($align: center)
        }
    }

    &-NavSlider {
        margin-block-start: 20px;

        .slick-slider {
            img {
                max-height: 94px;
            }
        }

        .slick-list {
            margin: 0 -4px;
        }

        .slick-slide {
            padding: 0 4px;
            cursor: pointer;
        }

        /* stylelint-disable-next-line */
        .slick-prev,
        .slick-next {
            background-color: $white;
            z-index: 1;
        }

        .slick-prev {
            inset-inline-start: 0px;

            @include mobile {
                inset-inline-start: -16px;
            }

            @include above1200 {
                inset-inline-start: -25px;
            }
        }

        .slick-next {
            inset-inline-end: 0px;

            @include mobile {
                inset-inline-end: -16px;
            }

            @include above1200 {
                inset-inline-end: -25px;
            }
        }

        .slick-track {
            @include flex($align: center)
        }
    }

    &-ActionButtons {
        position: absolute;
        inset-block-start: 12px;
        inset-inline-end: 6px;
        z-index: 1;

        div {
            margin: 0 5px;
        }
    }

    &-FloatingButton {
        position: fixed;
        z-index: 400;
        inset-inline-start: 16px;
        inset-block-start: calc(var(--header-total-height) + 16px);
        width: 192px;
        height: 40px;
        border: 1px solid $color-primary;
        background: $white;
        cursor: pointer;
        overflow: hidden;

        @include flex($dir: column, $justify: space-between, $align: center);

        @include desktop {
            height: 160px;
            inset-block-start: calc(var(--header-total-height) + 16px);
            inset-inline-start: 32px;
        }

        @include wide-desktop {
            inset-block-start: var(--header-total-height);
        }

        img {
            max-height: 115px;
            width: auto;
        }

        &::before {
            content: '';
            position: absolute;
            inset-block-end: 0px;
            inset-inline-start: 0px;
            background: $color-primary;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 27px 0px 0 27px;
            border-color: $white transparent transparent transparent;
        }
    }

    &-FloatingText {
        color: $color-primary;
        font-weight: $fw-medium;
        font-size: $fs-s;
        line-height: 16px;
        padding-block-end: 18px;

        @include mobile {
            padding: 11px 0 10px 12px;
        }
    }

    .ImageMagnify {
        max-height: var(---slider-height);
    }
}
