@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage_ArrangementPage {
    overflow-y: visible;
    overflow-x: clip;
}

.ProductGallery {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;

    @include mobileAndTablet {
        flex-direction: column;
        margin-block-end: 30px;
    }

    &-ActionButtons {
        @include mobile {
            margin-inline-end: 10px;
        }
    }

    &-Additional {
        width: 100%;

        @include mobileAndTablet {
            display: block;
            padding-inline-start: 15px;
            margin-block-end: 12px;
        }

        @include tablet {
            padding-inline-start: 0;
        }

        .CarouselScroll {
            width: 100%;
            margin: 0;

            &-ContentWrapper {
                width: 100%;

                .CarouselScrollItem {
                    width: 214px;
                    height: auto;
                    filter: opacity(.8);
                    border: none;

                    @include mobile {
                        width: 122px;
                        max-width: 214px;
                    }

                    &:focus,
                    &:hover {
                        filter: opacity(1);
                    }

                    &_isActive {
                        filter: opacity(1);
                        border: none;
                    }

                    .ProductGalleryThumbnailImage {
                        padding: 0;

                        img {
                            position: relative;
                            max-height: 120px;
                        }
                    }
                }
            }
        }

        .CarouselScrollArrow {
            position: absolute;
            inset-inline-start: -30px;
            padding-block-start: 2px;

            @include mobile {
                border: 1px solid #e8e9e9;
                background: $white;
                inset-inline-start: -5px;
                height: 35px;
                width: 35px;
            }

            &_isNextArrow {
                inset-inline-start: auto;
                inset-inline-end: -30px;

                @include mobile {
                    inset-inline-end: 5px;
                }
            }
        }
    }

    &-SliderWrapper {
        @include desktop {
            z-index: 2;
        }

        @include mobileAndTablet {
            padding: 0 15px;
            width: 100%;
        }

        @include tablet {
            padding: 0;
        }

        .Image {
            max-height: var(--slider-height);

            @include mobileAndTablet {
                min-height: var(--slider-height);
            }

            &_isActive {
                @include mobileAndTablet {
                    min-height: unset;
                }
            }
        }
    }

    .Product-PriceWrapper {
        width: 33%;

        @include mobileAndTablet {
            width: 100%;
            padding: 0 16px;
        }

        @include tablet {
            padding: 0;
        }

        .ProductDiscountLabel {
            @include mobile {
                margin-inline-start: 16px;
            }
        }

        .Product-PriceSection {
            @include tablet {
                display: inline-flex;
                flex-wrap: wrap;
                margin-block-start: 16px;
            }

            div,
            .Product-DiscountTitle {
                @include tablet {
                    width: 30%;
                }
            }

            .Product-PriceWrapper {
                @include tablet {
                    width: 70%;
                }
            }

            .ProductDiscountLabel {
                @include tablet {
                    width: auto;
                    margin-inline-start: 0;
                }
            }
        }
    }

    &-Slider {
        overflow-y: visible;
        overflow-x: clip;

        .Image {
            height: auto;
        }

        .react-transform-component {
            display: flex;
            height: auto;
        }

        .Slider-Wrapper {
            align-items: stretch;
        }
    }

    .ImageWithHotspots {
        display: flex;
        align-items: center;
        height: 100%;

        .Image {
            margin-block: auto;

            &-HeightFull {
                height: auto;
            }
        }
    }

    .ArrangementProductGallery-SliderWrapper {
        .VideoThumbnail-Thumbnail {
            padding-block-end: 50%;
        }
    }
}

.ImageZoomPopup {
    .ProductActions {
        display: none;
    }

    .ProductGallery {
        &-Slider {
            max-height: var(--slider-height);
            overflow: hidden;

            .ImageWithHotspots {
                &::before {
                    content: "";
                    padding-block-end: 56.25%;
                }

                &>.Image {
                    position: absolute;
                    inset-block-start: 0;
                    inset-inline-start: 50%;
                    padding-block-end: 0;
                    transform: translateX(-50%);
                    height: 100%;
                    width: auto;

                    &>img {
                        height: 100%;
                        width: auto;
                        position: static;
                    }
                }
            }

            &>.Image {
                height: 100%;
                padding-block-end: 0;
            }
        }

        &-Additional {
            @include desktop {
                z-index: 3;
            }
        }

        .ArrangementProductGallery {
            &-SliderWrapper {
                .VideoThumbnail-Thumbnail {
                    padding-block-end: 50%;
                }
            }
        }
    }
}
